import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RouterApp } from '@app/components/Router';
import { OrderOfferPage } from '@app/pages/orderOffer';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { PageLoading } from '@app/crossSelling/presentation/components/PageLoading';
import loadable from '@loadable/component';

const AppLoader = memo(function AppLoader({ orderId, flow, mock, pWebView, revamp = false }) {
  const { isParcel } = usePublicEnv();

  const Offer = loadable(() => import('@app/crossSelling/presentation/pages/Offer'), {
    resolveComponent: (component) => component.Offer,
    fallback: <PageLoading />,
  });

  const RouterV2 = loadable(() => import('@app/crossSelling/presentation/components/Router'), {
    resolveComponent: (component) => component.RouterApp,
    fallback: <PageLoading />,
  });

  if ((isParcel === true || isParcel === undefined) && !revamp) {
    return <OrderOfferPage orderId={orderId} flow={flow} mock={mock} />;
  }

  if ((isParcel === true || isParcel === undefined) && revamp) {
    return <Offer orderId={orderId} flow={flow} mock={mock} />;
  }

  return revamp ? <RouterV2 /> : <RouterApp />;
});

AppLoader.defaultProps = {
  orderId: '',
  flow: 'orderStatus',
  mock: false,
  pWebView: false,
  revamp: false,
};

AppLoader.propTypes = {
  orderId: PropTypes.string,
  flow: PropTypes.string,
  mock: PropTypes.bool,
  pWebView: PropTypes.bool,
  revamp: PropTypes.bool,
};

export { AppLoader };
