import React from 'react';
import { Container } from '@app/components/Container';
import Skeleton from '@pedidosya/web-fenix/system/Skeleton';

export const VendorRowSkeleton = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      padding: '24px 16px 16px 16px',
      justifyContent: 'space-between',
    }}
  >
    <div style={{ display: 'flex', gap: 12 }}>
      <div style={{ paddingRight: 12 }}>
        <Skeleton
          borderRadiusSize="large"
          height="shape-size-action-14"
          width="shape-size-action-14"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <Skeleton borderRadiusSize="large" width="48.25" />
        <Skeleton borderRadiusSize="large" width="34.25" />
      </div>
    </div>
  </div>
);

export const ProductCardSVerticalSkeleton = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
    }}
  >
    <div style={{ paddingBottom: 12 }}>
      <Skeleton borderRadiusSize="large" height="31.75" width="31.75" />
    </div>

    <div style={{ paddingBottom: 16 }}>
      <Skeleton borderRadiusSize="medium" height="shape-size-action-05" width="18.75" />
    </div>

    <div style={{ paddingBottom: 8 }}>
      <Skeleton borderRadiusSize="medium" height="shape-size-action-05" width="25.75" />
    </div>
    <Skeleton
      borderRadiusSize="medium"
      height="shape-size-action-05"
      width="shape-size-action-14"
    />
  </div>
);

export const ScrollableProductCardSVertical = () => (
  <div
    style={{
      display: 'flex',
      overflow: 'hidden',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      scrollbarWidth: 'none',
    }}
  >
    {[...Array(9)].map((_, index) => (
      <ProductCardSVerticalSkeleton key={index} />
    ))}
  </div>
);

export const PageLoading = () => {
  return (
    <div>
      <VendorRowSkeleton />
      <ScrollableProductCardSVertical />
      <Container pLeft="0" pRight="0" pTop="20">
        <ScrollableProductCardSVertical />
      </Container>
    </div>
  );
};
